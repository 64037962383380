import ActionNode = require("Everlaw/UI/ActionNode");
import Dom = require("Everlaw/Dom");
import Icon = require("Everlaw/UI/Icon");
import Str = require("Everlaw/Core/Str");
import { clsx } from "clsx";

/**
 * An ActionNode with the following additional properties:
 *  - icon: the created icon
 *  - label: the div containing the label text; its content can be changed as well
 */
class LabeledIcon extends ActionNode {
    label: HTMLElement;
    icon: Icon;
    constructor(iconClass: string, params: LabeledIcon.Params) {
        super(
            Dom.create(
                "div",
                {
                    class: clsx(params.outerClass, { "horizontal-labeled-icon": !params.vertical }),
                },
                params.parent,
            ),
            params,
        );
        this.icon = new Icon(iconClass, { parent: this.node, alt: params.alt || "" });
        this.label = Dom.create(
            params.vertical ? "div" : "span",
            {
                class:
                    "label"
                    + (params.noMargin ? " no-margin" : "")
                    + (params.class ? " " + params.class : ""),
                content: params.label || Str.capitalize(iconClass),
            },
            this.node,
            !params.vertical && params.right ? "first" : "last",
        );
        if (params.makeFocusable) {
            Dom.place(this.focusDiv.node, this.label, "after");
        }
    }
}

module LabeledIcon {
    export interface Params extends ActionNode.Params {
        // The Content representing the label text.
        label?: Dom.Content;
        // Accessible label for icon. Only use this if the icon and label don't mean the same thing.
        alt?: string;
        // The parent node of the outermost container node.
        parent?: string | Node;
        // If true, the label is below the icon instead of to the right of it. However, you should
        // almost always use an IconButton with a label instead of this option.
        vertical?: boolean;
        // If true, the icon will be on the right side of the label (only if horizontal is true.)
        right?: boolean;
        // Class to add to labeledIcon.
        class?: string;
        // Class to apply to whole icon.
        outerClass?: string;
        // No margin between the icon and label
        noMargin?: boolean;
    }
}

export = LabeledIcon;
